import {Link} from "react-router-dom"
import config from "../config"
import "./Header.css";
import image_title from "../images/title.png";
import rex_logo from "../images/rex-logo.png";

export default function _(props){
    return <div className={"SectionsHeader"+(
        props.real ? "  SectionsHeader--Real" : ""
    )+(
        props.shadow ? "  SectionsHeader--Shadow" : ""
    )}>
        <div className="SectionsHeader__Contents">
            <div className="SectionsHeader__Column  SectionsHeader__Column--Left">
                <div className="SectionsHeader__Link  SectionsHeader__Link--Logo--Left">
                    <Link className="" to={"/"}>
                        <img className="SectionsHeader__Logo" src={image_title}/>
                    </Link>
                </div>
            </div>
            <div className="SectionsHeader__Column  SectionsHeader__Column--Center">
                <div className="SectionsHeader__Link">
                    <Link className="" to={"/"}> Home </Link>
                </div>
                <div className="SectionsHeader__Spacer"> | </div>
                <div className="SectionsHeader__Link">
                    {/*<a href={config.href_buy} target="_blank" rel="noreferrer"> Buy </a>*/}
                    <Link className="" to={"/buy"}> Buy </Link>
                </div>
                <div className="SectionsHeader__Spacer"> | </div>
                <div className="SectionsHeader__Link">
                    <Link className="" to={"/learn"}> Rules </Link>
                </div>
                <div className="SectionsHeader__Spacer"> | </div>
                <div className="SectionsHeader__Link">
                    <Link className="" to={"/library"}> Library </Link>
                </div>
            </div>
            <div className="SectionsHeader__Column  SectionsHeader__Column--Right">
                <div className="SectionsHeader__Link  SectionsHeader__Link--Logo--Right">
                    <a href={config.href_laboriousrex} target="_blank" rel="noreferrer">
                        <img className="SectionsHeader__Logo" src={rex_logo}/>
                    </a>
                </div>
            </div>
        </div>
    </div>
}