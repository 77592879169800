
import "./WizardRules.css";
import EmailWidget from "./EmailWidget";
import config from "../config";

export default function _(props){
    
    return <div className="PagesWizardRules  Layout__Page  Decoration__Page">
        <div className="PagesWizardRules__Section  Decoration__Heading">
            WIZARD RULES
        </div>
        <div className="PagesWizardRules__Section  Decoration__TextSection">
        Oh ho ho... you wait just a minute, there. You don’t need to read these rules. Only if you have a very specific question about the game should you consult the Wizard Rules. Otherwise you may be reading them until you are an old wizard yourself...
        </div>
        <div className="PagesWizardRules__Section  Decoration__TextSection">
            <ul>
            <li>
                O brave are ye who read the wizard rules for they detail every tiny interaction and moment of play. For ye who shall want a casual game, move thine eyes to a different tome. For ye who want to play strictly by the book and the absolute rules, waggle thine eyes on forward.
            </li>
            <li>
                Overmage is not intended to be a competitive game. The cards and rules are designed such that, if they are misinterpreted, the game should still be fun. Please don’t worry about playing the game “correctly” - as long as you get the basic rules right, that’s all that matters. The intent of the Wizard Rules is to end arguments if they arise.
            </li>
            <li>When a card says “you” that always means the mage who played the card, even if the card is in another mage's Keep.</li>
            <li>If a card moves from one Keep to another, the mage who is the “Keeper” changes, but the “you” referred to by the card is still the mage who originally played the card.</li>
            <li>You cannot take negative damage. If 2 damage is reduced by 3, it becomes 0 damage.</li>
            <li>Any time you draw more than 1 card at the same time, don’t look at the cards until you’re finished drawing.</li>
            <li>Whenever something simultaneous happens, if the order matters, the turn player chooses the order the actions will be performed in.</li>
            <li>If you reach a part of a card’s effect you cannot perform (eg Discard 2 cards when you only have 1 card), that effect falis: stop there and do not perform the rest of that effect.</li>
            <li>Cards are in “limbo” while they’re in the middle of being played. They are not treated as in your hand, in the discard, or in a Keep, until the card tells you to put it in one of those places.</li>
            <li>If the effect of a card in "limbo" fails, it is discarded.</li>
            <li>If you die in the middle of performing a card’s effect, flip your mage over & do death stuff, but then continue to perform the effect of that card.</li>
            <li>Dead mages cannot have cards placed into their hand or Keep, but they can have cards removed from their hand or Keep. Dead mages cannot take damage or heal. Dead mages can still perform other actions, such as damaging other players with cards they previously played (eg “Draw Poison”). Note that some cards let dead mages keep their cards when they die. Those cards can still be taken/moved from their hand/Keep, via Cursebane, Birthday Party, etc...</li>
            <li>Dead mages can still win the game with their wishes, or with vendettas.</li>
            <li>As in all games, card mechanics trump game rules: if the rules say one thing, but a card says something else, do what the card says!</li>
            <li>If a card causes you to draw cards, you still get to draw 3 cards at the end of your turn.</li>
            <li>Doppelspell: The last two effects stack: If the card ends up in a Keep but leaves the keep, if it is still the turn that card was played, it remains a copy for the rest of the turn.</li>
            <li>Interruption - If a card says “you can play it when” or “do something when”, etc, do that thing in the middle of the other card’s effect. Here’s a very complicated example, but if you can understand it, you will be able to work out any interaction of cards that might happen:</li>
            <div className="PagesWizardRules__Sublist">
               <ul>
                    <li>Alice has 9 HP.</li>
                    <li>Alice has “Draw Poison” and “Spell Poison” in their Keep.</li>
                    <li>Alice plays “Immolation”</li>
                    <li>“Spell Poison” happens now. Alice takes 2 damage. (Alice has 7 HP).</li>
                    <li>The first sentence of “Immolation” happens: Alice will take 4 damage.</li>
                    <li>Bob plays “Hollow Tree” because Alice is about to take damage.</li>
                    <li>“Hollow Tree” happens now, so the damage of “Immolation" is reduced by 4.</li>
                    <li>Alice takes no damage. (Alice has 7 HP).</li>
                    <li>Alice then draws 2 cards. She draws “Endless Cathedral” (a DRAW-PLAY-DRAW card) and “Cursebane”.</li>
                    <li>Alice will take 4 damage from “Draw Poison” (from drawing “Cursebane” and “Endless Cathedral”) and will play “Endless Cathedral.” Because she is the turn player, she can choose the order, so she chooses to play “Endless Cathedral” first.</li>
                    <li>Alice takes 2 damage from “Spell Poison” because she played a card (Alice has 5 HP). “Endless Cathedral” causes her to draw another card. She draws “Prosperity.”</li>
                    <li>Alice will take 2 damage from “Draw Poison” (from drawing “Prosperity”) and will play “Prosperity.” She chooses to play “Prosperity” first.</li>
                    <li>Alice plays “Prosperity.”</li>
                    <li>Alice takes 2 damage from “Spell Poison” (from playing “Prosperity”)</li>
                    <li>“Prosperity” causes Alice to draw another card (“Borrow”).</li>
                    <li>Alice takes 2 damage from “Draw Poison” (from drawing “Borrow”). (Alice has 3 HP).</li>
                    <li>“Prosperity” heals everyone 5. (Alice has 8 HP).</li>
                    <li>Alice takes the 2 damage from “Draw Poison” (from drawing “Prosperity”). (Alice has 6 HP).</li>
                    <li>Alice takes the 4 damage from “Draw Poison” (from drawing “Cursebane” and “Endless Cathedral”) (Alice has 2 HP).</li>
                </ul>
            </div>
            <li>There’s a lot more things to put here. I have a list somewhere. It’s not important. Just have fun!</li>
            <li>If you do have any questions, though, just send us an email / <a href={config.href_discord} target="_blank" rel="noreferrer">message us on discord</a>! <EmailWidget/></li>
            </ul>
        </div>
    </div>
}