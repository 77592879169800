

import {Dropdown,Button} from "semantic-ui-react";

import image_backs from "../images/backs.jpg";
import image_box_side from "../images/box_side.jpg";
import image_card_red from "../images/card_red.jpg";
import image_cards_orange from "../images/cards_orange.jpg";
import image_cards_violet from "../images/cards_purple.jpg";
import image_cards_red from "../images/cards_red.jpg";
import image_cards_yellow from "../images/cards_yellow.jpg";
import image_card_violet from "../images/card_violet.jpg";
import image_card_yellow from "../images/card_yellow.jpg";
import image_contents from "../images/contents.jpg";
import image_cover from "../images/cover.jpg";
import image_dead from "../images/dead.jpg";
import image_hand from "../images/hand.jpg";
import image_mages from "../images/mages.jpg";
import image_title from "../images/title.png";
import image_turnaround from "../images/turnaround.gif";
import image_wishes from "../images/wishes.jpg";
import image_wishes_backs from "../images/wishes_backs.jpg";
import React from "react";
import config from "../config";

import "./Buy.css";

const base_cost = 40;

const zones =  [
    {
        shipping: "",
        cost: 0,
    },
    {
        reward_id: 7014,
        shipping: "USA",
        cost: 8,
    },
    {
        reward_id: 7455,
        shipping: "Canada / Mexico",
        cost: 20,
    },
    {
        reward_id: 7456,
        shipping: "Europe / Japan / Worldwide",
        cost: 25,
    },
];

for(const zone of zones){
    if( zone.reward_id)
        zone.text = `Ship to ${zone.shipping}: +$${zone.cost}`;
    else
        zone.text = "Choose Shipping"
}


export default function _(props){
    const [zone, set_zone] = React.useState(zones[0]);

    return <>
        <div className="Decoration__Heading  Layout__Heading  PagesBuy__Heading">Buy Overmage</div>
        <p className="PagesBuy__TextSection">You can buy Overmage through the widget here, or <a target="_blank" rel="noreferrer" href={config.href_itch_checkout}>on our Itch.io page!</a></p>
        <p className="PagesBuy__TextSection">It will ask you for your address after checkout!</p>
        <Dropdown
            type="select"
            button
            className="icon"
            labeled
            icon="globe"
            options={zones.map(_=>{
            return {
                text:_.text,
                key:_.reward_id,
                value:_}
            })}
            text={zone.text}
            onChange={ (e,data)=>{set_zone(data.value);}}
        />
        <a
            onClick={()=>{
                if( zone.reward_id) window.open(`https://laboriousrex.itch.io/overmage/purchase?reward_id=${zone.reward_id}`,"popup","width=800,height=650")
            }} target="popup">
            <Button disabled={!zone.reward_id}>Buy Overmage for ${base_cost}{zone.reward_id && ` + $${zone.cost} shipping`}</Button>
        </a>
        <img className="PagesBuy__SmallImage" src={image_turnaround}/>
        <p className="PagesBuy__TextSection">Contains 142 cards, 20 coins, durable felt bags, and a rulebook, all stored in a sturdy box. This first edition print is from a limited run of 100.</p>
    </>
}