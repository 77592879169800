

import {Dropdown,Button} from "semantic-ui-react";

import image_backs from "../images/backs.jpg";
import image_box_side from "../images/box_side.jpg";
import image_card_red from "../images/card_red.jpg";
import image_cards_orange from "../images/cards_orange.jpg";
import image_cards_violet from "../images/cards_purple.jpg";
import image_cards_red from "../images/cards_red.jpg";
import image_cards_yellow from "../images/cards_yellow.jpg";
import image_card_violet from "../images/card_violet.jpg";
import image_card_yellow from "../images/card_yellow.jpg";
import image_contents from "../images/contents.jpg";
import image_cover from "../images/cover.jpg";
import image_dead from "../images/dead.jpg";
import image_hand from "../images/hand.jpg";
import image_mages from "../images/mages.jpg";
import image_title from "../images/title.png";
import image_turnaround from "../images/turnaround.gif";
import image_wishes from "../images/wishes.jpg";
import image_wishes_backs from "../images/wishes_backs.jpg";
import React from "react";
import config from "../config";

import "./Buy.css";
import BuyWidget from "./Buy__Widget";


export default function _(props){

    return <div className="PagesBuy  Layout__Page  Decoration__Page">
        <div className="PagesBuy__Column  PagesBuy__Column--Left">
            <img className="PagesBuy__BigImage" src={image_cover}/>
        </div>
        <div className="PagesBuy__Column  PagesBuy__Column--Right">
            <BuyWidget/>
        </div>
    </div>
    
}