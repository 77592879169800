let cards = [
{
    filename: "card-orange—Aegis-of-Autumn.png.jpg",
    href: require("../images/library/card-orange—Aegis-of-Autumn.png.jpg").default
},
{
    filename: "card-orange—Apple-of-Perfection.png.jpg",
    href: require("../images/library/card-orange—Apple-of-Perfection.png.jpg").default
},
{
    filename: "card-orange—Armor-Memorious.png.jpg",
    href: require("../images/library/card-orange—Armor-Memorious.png.jpg").default
},
{
    filename: "card-orange—Book-in-Brambles.png.jpg",
    href: require("../images/library/card-orange—Book-in-Brambles.png.jpg").default
},
{
    filename: "card-orange—Calvar-Cloak.png.jpg",
    href: require("../images/library/card-orange—Calvar-Cloak.png.jpg").default
},
{
    filename: "card-orange—Chain-of-Leaves.png.jpg",
    href: require("../images/library/card-orange—Chain-of-Leaves.png.jpg").default
},
{
    filename: "card-orange—Doppelspell.png.jpg",
    href: require("../images/library/card-orange—Doppelspell.png.jpg").default
},
{
    filename: "card-orange—Enveloping-Grove.png.jpg",
    href: require("../images/library/card-orange—Enveloping-Grove.png.jpg").default
},
{
    filename: "card-orange—Fancy-Hat.png.jpg",
    href: require("../images/library/card-orange—Fancy-Hat.png.jpg").default
},
{
    filename: "card-orange—Hollow-Tree.png.jpg",
    href: require("../images/library/card-orange—Hollow-Tree.png.jpg").default
},
{
    filename: "card-orange—In-Deed.png.jpg",
    href: require("../images/library/card-orange—In-Deed.png.jpg").default
},
{
    filename: "card-orange—Inheritance-Chest.png.jpg",
    href: require("../images/library/card-orange—Inheritance-Chest.png.jpg").default
},
{
    filename: "card-orange—Lock-and-Chain.png.jpg",
    href: require("../images/library/card-orange—Lock-and-Chain.png.jpg").default
},
{
    filename: "card-orange—New-Leaf.png.jpg",
    href: require("../images/library/card-orange—New-Leaf.png.jpg").default
},
{
    filename: "card-orange—Obsidian-Cloak.png.jpg",
    href: require("../images/library/card-orange—Obsidian-Cloak.png.jpg").default
},
{
    filename: "card-orange—Petrified-Well.png.jpg",
    href: require("../images/library/card-orange—Petrified-Well.png.jpg").default
},
{
    filename: "card-orange—Rusty-Tortoise.png.jpg",
    href: require("../images/library/card-orange—Rusty-Tortoise.png.jpg").default
},
{
    filename: "card-orange—Tax-Box.png.jpg",
    href: require("../images/library/card-orange—Tax-Box.png.jpg").default
},
{
    filename: "card-orange—The-Golden-Fleece.png.jpg",
    href: require("../images/library/card-orange—The-Golden-Fleece.png.jpg").default
},
{
    filename: "card-orange—The-Holy-Grail.png.jpg",
    href: require("../images/library/card-orange—The-Holy-Grail.png.jpg").default
},
{
    filename: "card-orange—Tinfoil-Tunic.png.jpg",
    href: require("../images/library/card-orange—Tinfoil-Tunic.png.jpg").default
},
{
    filename: "card-orange—Two-Legged-Cabin.png.jpg",
    href: require("../images/library/card-orange—Two-Legged-Cabin.png.jpg").default
},
{
    filename: "card-red—Book-of-Flames.png.jpg",
    href: require("../images/library/card-red—Book-of-Flames.png.jpg").default
},
{
    filename: "card-red—Coal-Grenade.png.jpg",
    href: require("../images/library/card-red—Coal-Grenade.png.jpg").default
},
{
    filename: "card-red—Doppelspell.png.jpg",
    href: require("../images/library/card-red—Doppelspell.png.jpg").default
},
{
    filename: "card-red—Earthquake.png.jpg",
    href: require("../images/library/card-red—Earthquake.png.jpg").default
},
{
    filename: "card-red—Excaliburn.png.jpg",
    href: require("../images/library/card-red—Excaliburn.png.jpg").default
},
{
    filename: "card-red—Fire-Bow.png.jpg",
    href: require("../images/library/card-red—Fire-Bow.png.jpg").default
},
{
    filename: "card-red—Fire-Whip.png.jpg",
    href: require("../images/library/card-red—Fire-Whip.png.jpg").default
},
{
    filename: "card-red—Gather-Power.png.jpg",
    href: require("../images/library/card-red—Gather-Power.png.jpg").default
},
{
    filename: "card-red—Immolation.png.jpg",
    href: require("../images/library/card-red—Immolation.png.jpg").default
},
{
    filename: "card-red—King-Coals-Cloak.png.jpg",
    href: require("../images/library/card-red—King-Coals-Cloak.png.jpg").default
},
{
    filename: "card-red—King-Coals-Crown.png.jpg",
    href: require("../images/library/card-red—King-Coals-Crown.png.jpg").default
},
{
    filename: "card-red—Looming-Fireball.png.jpg",
    href: require("../images/library/card-red—Looming-Fireball.png.jpg").default
},
{
    filename: "card-red—Melt.png.jpg",
    href: require("../images/library/card-red—Melt.png.jpg").default
},
{
    filename: "card-red—Monkeys-Game.png.jpg",
    href: require("../images/library/card-red—Monkeys-Game.png.jpg").default
},
{
    filename: "card-red—Oven-Gauntlets.png.jpg",
    href: require("../images/library/card-red—Oven-Gauntlets.png.jpg").default
},
{
    filename: "card-red—Redhand-Market.png.jpg",
    href: require("../images/library/card-red—Redhand-Market.png.jpg").default
},
{
    filename: "card-red—Scorchbreath.png.jpg",
    href: require("../images/library/card-red—Scorchbreath.png.jpg").default
},
{
    filename: "card-red—Smolder-Wolf.png.jpg",
    href: require("../images/library/card-red—Smolder-Wolf.png.jpg").default
},
{
    filename: "card-red—The-Cinders.png.jpg",
    href: require("../images/library/card-red—The-Cinders.png.jpg").default
},
{
    filename: "card-red—Twisted-Embers.png.jpg",
    href: require("../images/library/card-red—Twisted-Embers.png.jpg").default
},
{
    filename: "card-red—Volcanic-Potion.png.jpg",
    href: require("../images/library/card-red—Volcanic-Potion.png.jpg").default
},
{
    filename: "card-red—Whiplash.png.jpg",
    href: require("../images/library/card-red—Whiplash.png.jpg").default
},
{
    filename: "card-violet—Big-Steal.png.jpg",
    href: require("../images/library/card-violet—Big-Steal.png.jpg").default
},
{
    filename: "card-violet—Black-Lion.png.jpg",
    href: require("../images/library/card-violet—Black-Lion.png.jpg").default
},
{
    filename: "card-violet—Cave-of-Fates.png.jpg",
    href: require("../images/library/card-violet—Cave-of-Fates.png.jpg").default
},
{
    filename: "card-violet—Cemetery.png.jpg",
    href: require("../images/library/card-violet—Cemetery.png.jpg").default
},
{
    filename: "card-violet—Curse-of-Cowardice.png.jpg",
    href: require("../images/library/card-violet—Curse-of-Cowardice.png.jpg").default
},
{
    filename: "card-violet—Curse-of-Imperfection.png.jpg",
    href: require("../images/library/card-violet—Curse-of-Imperfection.png.jpg").default
},
{
    filename: "card-violet—Curse-of-Werewolf.png.jpg",
    href: require("../images/library/card-violet—Curse-of-Werewolf.png.jpg").default
},
{
    filename: "card-violet—Dark-Legacy.png.jpg",
    href: require("../images/library/card-violet—Dark-Legacy.png.jpg").default
},
{
    filename: "card-violet—Doppelspell.png.jpg",
    href: require("../images/library/card-violet—Doppelspell.png.jpg").default
},
{
    filename: "card-violet—Draw-Poison.png.jpg",
    href: require("../images/library/card-violet—Draw-Poison.png.jpg").default
},
{
    filename: "card-violet—Famine.png.jpg",
    href: require("../images/library/card-violet—Famine.png.jpg").default
},
{
    filename: "card-violet—Haunted-Chandelier.png.jpg",
    href: require("../images/library/card-violet—Haunted-Chandelier.png.jpg").default
},
{
    filename: "card-violet—Nightmares.png.jpg",
    href: require("../images/library/card-violet—Nightmares.png.jpg").default
},
{
    filename: "card-violet—Obsidian-Scythe.png.jpg",
    href: require("../images/library/card-violet—Obsidian-Scythe.png.jpg").default
},
{
    filename: "card-violet—Plague-of-Crows.png.jpg",
    href: require("../images/library/card-violet—Plague-of-Crows.png.jpg").default
},
{
    filename: "card-violet—Plague-of-Rats.png.jpg",
    href: require("../images/library/card-violet—Plague-of-Rats.png.jpg").default
},
{
    filename: "card-violet—Serpents-Tongue.png.jpg",
    href: require("../images/library/card-violet—Serpents-Tongue.png.jpg").default
},
{
    filename: "card-violet—Silent-Thunder.png.jpg",
    href: require("../images/library/card-violet—Silent-Thunder.png.jpg").default
},
{
    filename: "card-violet—Spell-Poison.png.jpg",
    href: require("../images/library/card-violet—Spell-Poison.png.jpg").default
},
{
    filename: "card-violet—Spirit-Away.png.jpg",
    href: require("../images/library/card-violet—Spirit-Away.png.jpg").default
},
{
    filename: "card-violet—Taste-for-Flesh.png.jpg",
    href: require("../images/library/card-violet—Taste-for-Flesh.png.jpg").default
},
{
    filename: "card-violet—Visitation.png.jpg",
    href: require("../images/library/card-violet—Visitation.png.jpg").default
},
{
    filename: "card-violet—Vultures-Veil.png.jpg",
    href: require("../images/library/card-violet—Vultures-Veil.png.jpg").default
},
{
    filename: "card-wish_black—Anarchist.png.jpg",
    href: require("../images/library/card-wish_black—Anarchist.png.jpg").default
},
{
    filename: "card-wish_black—Assassin.png.jpg",
    href: require("../images/library/card-wish_black—Assassin.png.jpg").default
},
{
    filename: "card-wish_black—Martyr.png.jpg",
    href: require("../images/library/card-wish_black—Martyr.png.jpg").default
},
{
    filename: "card-wish_black—Mastermind.png.jpg",
    href: require("../images/library/card-wish_black—Mastermind.png.jpg").default
},
{
    filename: "card-wish_black—Ripper.png.jpg",
    href: require("../images/library/card-wish_black—Ripper.png.jpg").default
},
{
    filename: "card-wish_black—Tempter.png.jpg",
    href: require("../images/library/card-wish_black—Tempter.png.jpg").default
},
{
    filename: "card-wish_white—Architect.png.jpg",
    href: require("../images/library/card-wish_white—Architect.png.jpg").default
},
{
    filename: "card-wish_white—Mayor.png.jpg",
    href: require("../images/library/card-wish_white—Mayor.png.jpg").default
},
{
    filename: "card-wish_white—Mentor.png.jpg",
    href: require("../images/library/card-wish_white—Mentor.png.jpg").default
},
{
    filename: "card-wish_white—Messiah.png.jpg",
    href: require("../images/library/card-wish_white—Messiah.png.jpg").default
},
{
    filename: "card-wish_white—Philosopher.png.jpg",
    href: require("../images/library/card-wish_white—Philosopher.png.jpg").default
},
{
    filename: "card-wish_white—Romantic.png.jpg",
    href: require("../images/library/card-wish_white—Romantic.png.jpg").default
},
{
    filename: "card-wish_white—Storywriter.png.jpg",
    href: require("../images/library/card-wish_white—Storywriter.png.jpg").default
},
{
    filename: "card-yellow—Birthday-Party.png.jpg",
    href: require("../images/library/card-yellow—Birthday-Party.png.jpg").default
},
{
    filename: "card-yellow—Borrow.png.jpg",
    href: require("../images/library/card-yellow—Borrow.png.jpg").default
},
{
    filename: "card-yellow—Brutal-Honesty.png.jpg",
    href: require("../images/library/card-yellow—Brutal-Honesty.png.jpg").default
},
{
    filename: "card-yellow—Cursebane.png.jpg",
    href: require("../images/library/card-yellow—Cursebane.png.jpg").default
},
{
    filename: "card-yellow—Curse-of-Werecat.png.jpg",
    href: require("../images/library/card-yellow—Curse-of-Werecat.png.jpg").default
},
{
    filename: "card-yellow—Doppelspell.png.jpg",
    href: require("../images/library/card-yellow—Doppelspell.png.jpg").default
},
{
    filename: "card-yellow—Endless-Cathedral.png.jpg",
    href: require("../images/library/card-yellow—Endless-Cathedral.png.jpg").default
},
{
    filename: "card-yellow—Ham-of-Sharing.png.jpg",
    href: require("../images/library/card-yellow—Ham-of-Sharing.png.jpg").default
},
{
    filename: "card-yellow—Keg-of-Sharing.png.jpg",
    href: require("../images/library/card-yellow—Keg-of-Sharing.png.jpg").default
},
{
    filename: "card-yellow—Knotted-Wand.png.jpg",
    href: require("../images/library/card-yellow—Knotted-Wand.png.jpg").default
},
{
    filename: "card-yellow—Luminous-Kitten.png.jpg",
    href: require("../images/library/card-yellow—Luminous-Kitten.png.jpg").default
},
{
    filename: "card-yellow—Magic-Circle.png.jpg",
    href: require("../images/library/card-yellow—Magic-Circle.png.jpg").default
},
{
    filename: "card-yellow—Marriage-License.png.jpg",
    href: require("../images/library/card-yellow—Marriage-License.png.jpg").default
},
{
    filename: "card-yellow—Merlins-Beard.png.jpg",
    href: require("../images/library/card-yellow—Merlins-Beard.png.jpg").default
},
{
    filename: "card-yellow—Prosperity.png.jpg",
    href: require("../images/library/card-yellow—Prosperity.png.jpg").default
},
{
    filename: "card-yellow—Sharing-is-Caring.png.jpg",
    href: require("../images/library/card-yellow—Sharing-is-Caring.png.jpg").default
},
{
    filename: "card-yellow—Stigmata.png.jpg",
    href: require("../images/library/card-yellow—Stigmata.png.jpg").default
},
{
    filename: "card-yellow—Tower-of-the-Oracle.png.jpg",
    href: require("../images/library/card-yellow—Tower-of-the-Oracle.png.jpg").default
},
{
    filename: "card-yellow—Trusting-Negotiations.png.jpg",
    href: require("../images/library/card-yellow—Trusting-Negotiations.png.jpg").default
},
];
for( const card of cards ){
    let string = card.filename;
    string = string.substring("card-".length);
    card.color = string.split("—")[0];
    string = string.substring(card.color.length+1);
    string = string.split(".")[0];
    card.id = string.toLowerCase();
    card.title = string.split("-").join(" ");
}
const allIds = [];
for( const card of cards ){
    if( ! allIds.includes(card.id) ){
        allIds.push( card.id );
    }
}
// fix duplicate ids
for( const id of allIds ){
    const set = cards.filter(_=>_.id===id);
    if( set.length > 1 ){
        for(const card of set){
            card.id = card.id+"--"+card.color;
        }
    }
}
cards = cards.sort( (a,b) => a.title > b.title );

export default { cards };