
import "./WizardRules.css";
import EmailWidget from "./EmailWidget";
import img_indigo from "../images/indigo.png";

export default function _(props){
    
    return <div className=" Layout__Page  Decoration__Page">
        <div className="Decoration__TextSection">
            It seems you've found a secret expansion...
        </div>
        <img src={img_indigo}/>
        <div className="Decoration__TextSection">
            If you'd like to buy the indigo expansion deck, send us an email :)
        </div>
    </div>
}