import React from "react";

export default function _(props){
    const [email_address, set_email_address] = React.useState(<span>
        <button onClick={show_email_address}>click here to see our email address!</button>
    </span>);
    function show_email_address(){
        const address = "labo"+"riousre"+"x"+"@"+"gm"+"ail"+"."+"c"+"om";
        set_email_address(
            <a href={"mailto:"+address}>{address}</a>
        );
    }
    return email_address
}