
import "./WizardRules.css";

import React from "react";





export default function _(props){
  

    return <div className=" Layout__Page  Decoration__Page">
        <div className="Decoration__TextSection">
            Download minigame (windows/linux):<br/>
            <a href="https://overmage.com/AhBivaiy4wahchie/The Spiralcase Library.zip"
                target="_blank"
                rel="noreferrer"
            >
                The Spiralcase Library
            </a>
        </div>
        <div className="PagesTheFirstGate__Pickers">
        
        </div>
    </div>
}