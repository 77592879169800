
import "./WizardRules.css";

import {Dropdown} from "semantic-ui-react";

import library from "../resources/library";
import React from "react";

import "./TheFirstGate.css";
import { useHistory } from "react-router";

const dropdownOptions = library.cards.map(
    card=>({
        key: card.id,
        text: card.title,
        value: card.id
    })
);



export default function _(props){
    const history = useHistory();

    const [pickedCards,setPickedCards] = React.useState([
        library.cards[0],library.cards[0],library.cards[0],library.cards[0]
    ]);


    const combined = pickedCards.map(_=>_.id).sort().join("+");
    console.log(combined);
    if(combined==="excaliburn+knotted-wand+spell-poison+the-holy-grail"){
        history.push("/AhBivaiy4wahchie");
    }
    
    const buildCardPicker = index=><div className="PagesTheFirstGate__Picker">
        <Dropdown
            placeholder="Select a card"
            fluid
            search
            selection
            options={dropdownOptions}
            onChange={(e,data)=>{
                const id = data.value;
                pickedCards[index] = library.cards.find(_=>_.id===id);
                setPickedCards([...pickedCards]);
            }}
        />
        {
            pickedCards[index] && <img src={pickedCards[index].href}/>
        }
    </div>

    return <div className=" Layout__Page  Decoration__Page">
        <div className="Decoration__TextSection">
            Can you figure out the code?
        </div>
        <div className="PagesTheFirstGate__Pickers">
        {
            pickedCards.map( (card,index)=>buildCardPicker(index) )
        }
        </div>
    </div>
}