import image_backs from "../images/backs.jpg";
import image_box_side from "../images/box_side.jpg";
import image_card_red from "../images/card_red.jpg";
import image_cards_orange from "../images/cards_orange.jpg";
import image_cards_violet from "../images/cards_purple.jpg";
import image_cards_red from "../images/cards_red.jpg";
import image_cards_yellow from "../images/cards_yellow.jpg";
import image_card_violet from "../images/card_violet.jpg";
import image_card_yellow from "../images/card_yellow.jpg";
import image_contents from "../images/contents.jpg";
import image_cover from "../images/cover.jpg";
import image_dead from "../images/dead.jpg";
import image_hand from "../images/hand.jpg";
import image_mages from "../images/mages.jpg";
import image_title from "../images/title.png";
import image_turnaround from "../images/turnaround.gif";
import image_wishes from "../images/wishes.jpg";
import image_wishes_backs from "../images/wishes_backs.jpg";

import BuyWidget from "./Buy__Widget";
import "./Home.css";

export default function _(props){
    return <div className="PagesHome  Layout__Page  Decoration__Page">
        {/*<img className="PagesHome__Image  PagesHome__Image--Title" src={image_title}/>*/}
        <img className="PagesHome__Image  PagesHome__Image--Card" src={image_cover}/>
        <div className="Deocration__TextSection">
            Overmage is a magical game that creates a "living experience" for you and your friends. It's a fun-first world of strategy and chance, filled to the brim with chaotic-yet-satisfying card effects.
        </div>
        <img className="PagesHome__Image  PagesHome__Image--Card" src={image_mages}/>
        <img className="PagesHome__Image  PagesHome__Image--Card" src={image_hand}/>
        <div className="Deocration__TextSection">
            Freedom is also an important part of Overmage: you are allowed to draw and play cards from any deck, and each deck has its own unique style of magic.
        </div>
        <img className="PagesHome__Image  PagesHome__Image--Card" src={image_backs}/>
        <div className="Deocration__TextSection">
            Each playthrough of Overmage is unique: each round will have different win conditions, and each person will have their own playstyle and personality. This makes for a very replayable game!
        </div>
        <img className="PagesHome__Image  PagesHome__Image--Card" src={image_contents}/>
        <div className="Decoration__Heading  PagesHome__TextHeading">
            Life, Death, and Secrets
        </div>
        <div className="Deocration__TextSection">
            Although you start with 20 HP, winning is not all about combat. It's all about wishes.
        </div>
        <img className="PagesHome__Image  PagesHome__Image--Card" src={image_wishes_backs}/>
        <div className="Deocration__TextSection">
            You start with two wishes - one white wish and one black wish - these tell you how to win the game. You only need to fulfill one wish to win the game, but you may often find yourself changing direction along the way.
        </div>
        <img className="PagesHome__Image  PagesHome__Image--Card" src={image_wishes}/>
        <img className="PagesHome__Image  PagesHome__Image--Card" src={image_dead}/>
        <div className="Deocration__TextSection">
            In Overmage, death is but a minor setback. Those who have died are destined to be reborn, bearing a vendetta against their killer.
        </div>
        <div className="Decoration__Heading  PagesHome__TextHeading">
            Simple Rules, Unique Card Effects
        </div>
        <div className="Deocration__TextSection">
            This is the turn structure for the game:
            <ol>
                <li>Play cards from your hand & take special actions.</li>
                <li>Draw 3 cards.</li>
            </ol>
        </div>
        <img className="PagesHome__Image  PagesHome__Image--Card" src={image_card_red}/>
        <div className="Deocration__TextSection">
            Sometimes you will draw a card that gets played immediately. Surprise!
        </div>
        <img className="PagesHome__Image  PagesHome__Image--Card" src={image_card_yellow}/>
        <div className="Deocration__TextSection">
            And for extra spice, there are "location cards" that come into play. Anyone can "visit" these during their turn for a special effect.
        </div>
        <img className="PagesHome__Image  PagesHome__Image--Card" src={image_card_violet}/>
        <div className="Deocration__TextSection">
            The meat of the game is all in the varying effects that are on the cards. Generally speaking, red cards deal damage, orange cards give protection, yellow cards have cooperative/healing effects, and violet cards are for tormenting the other players.
        </div>
        <img className="PagesHome__Image  PagesHome__Image--Card" src={image_cards_red}/>
        <img className="PagesHome__Image  PagesHome__Image--Card" src={image_cards_orange}/>
        <img className="PagesHome__Image  PagesHome__Image--Card" src={image_cards_yellow}/>
        <img className="PagesHome__Image  PagesHome__Image--Card" src={image_cards_violet}/>
        <div className="Decoration__Heading  PagesHome__TextHeading">
            What's in the Box?
        </div>
        <div className="Deocration__TextSection">
            <ul>
                <li>4 Mage cards</li>
                <li>7 White Wishes</li>
                <li>7 Black Wishes</li>
                <li>31 card Red Spellbook</li>
                <li>31 card Orange Spellbook</li>
                <li>31 card Yellow Spellbook</li>
                <li>31 card Violet Spellbook</li>
                <li>Felt bag to hold all the cards</li>
                <li>Felt bag containing 20 wooden coins</li>
                <li>Rulebook</li>
                <li>Magic & fun</li>
            </ul>
        </div>
        <img className="PagesHome__Image  PagesHome__Image--Card" src={image_contents}/>
        <div className="PagesHome__BuyWidgetContainer">
            <BuyWidget />
        </div>
    </div>
}