
export default function _(props){
    return <div>
        <div className="Decoration__Heading">
            RULEBOOKS
        </div>
        <div className="Decoration__TextSection">
        <ul>
            <li><a
            href="https://www.overmage.com/downloadables/overmage-rulebook-updated-2020.06.06.pdf"
            target="_blank"
            rel="noopener noreferrer"
            >Download rulebook PDF</a></li>
            <li><a
            href="/wizard_rules"
            >Consult the "WIZARD RULES"</a></li>
        </ul>
        </div>
    </div>
}