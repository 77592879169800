import logo from './logo.svg';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Header from "./sections/Header";
import Footer from "./sections/Footer";
import PageHome from "./pages/Home";
import PageLibrary from "./pages/Library";
import PageLearn from "./pages/Learn";
import PageBuy from "./pages/Buy";
import WizardRules from "./pages/WizardRules";
import TheFinalGate from "./pages/TheFinalGate";
import TheSecondGate from "./pages/TheSecondGate";
import TheFirstGate from "./pages/TheFirstGate";
function App() {
  return (
    <div className="Layout__Root">
      <BrowserRouter>
        <Header shadow/>
        <Header real/>
        <Switch>
          <Route path="/library/the-spiralcase-library">
            <TheFirstGate />
          </Route>
          <Route path="/AhBivaiy4wahchie">
            <TheSecondGate />
          </Route>
          <Route path="/thefinalgate">
            <TheFinalGate />
          </Route>
          <Route path="/library/:id"
          render={
            props=> <PageLibrary id={props.match.params.id} />
          } />
          <Route path="/library">
            <PageLibrary />
          </Route>
          <Route path="/wizard_rules">
            <WizardRules />
          </Route>
          <Route path="/learn">
            <PageLearn />
          </Route>
          <Route path="/buy">
            <PageBuy />
          </Route>
          <Route path="/">
            <PageHome />
          </Route>
        </Switch>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
