import config from "../config"

import "./Footer.css";

export default function _(props){
    return <div className="SectionsFooter" align="center">
      <div className="SectionsFooter__Line">
      Overmage & Laborious Rex are © Skypeak LLC {get_year()}
      </div>
      <div className="SectionsFooter__Line">
        <a
          href={config.href_facebook}
          target="_blank"
          rel="noopener noreferrer"
        >Facebook</a>
        <span> | </span>
        <a
          href={config.href_instagram}
          target="_blank"
          rel="noopener noreferrer"
        >Instagram</a>
        <span> | </span>
        <a
          href={config.href_discord}
          target="_blank"
          rel="noopener noreferrer"
        >Discord</a>
        <span> | </span>
        <a
          href={config.href_reddit}
          target="_blank"
          rel="noopener noreferrer"
        >Reddit</a>
      </div>
      <div className="SectionsFooter__Line">
        <a
          href={config.href_skypeakllc}
          target="_blank"
          rel="noopener noreferrer"
        >SkypeakLLC.com</a>
        <span> | </span>
        <a
        href={config.href_laboriousrex}
        target="_blank"
        rel="noopener noreferrer"
        >LaboriousRex.com</a>
      </div>
    </div>
}

function get_year(){
    return new Date().getFullYear()
}