const config = {
    href_itch: "https://laboriousrex.itch.io/overmage",
    href_itch_checkout: "https://laboriousrex.itch.io/overmage",
    href_facebook: "http://facebook.com/laboriousrexofficial",
    href_instagram: "https://www.instagram.com/laboriousrexofficial/",
    href_discord: "https://discord.gg/BsQ4fXf",
    href_reddit: "https://www.reddit.com/user/Laborious_Rex/",
    href_skypeakllc: "https://www.skypeakllc.com",
    href_laboriousrex: "https://www.laboriousrex.com",
    url_post_checkout: "http://localhost:3008/api/checkout",
    url_post_email_signup: "http://localhost:3008/api/email_signup"
}

export default config;