import React from "react";
import { act } from "react-dom/test-utils";
import { Link, useHistory } from "react-router-dom";
import library from "../resources/library";

import "./Library.css";

export default function _(props){
    const history = useHistory();
    const [activeCard, setActiveCard] = React.useState(
        props.id ? library.cards.find(_=>_.id===props.id) :
        library.cards[0]
    );
    const [filter, setFilter] = React.useState("");

    const filterLower = filter.toLowerCase();
    const filteredCards = library.cards.filter(
        card=>
            card.color.includes(filterLower) ||
            card.title.toLowerCase().includes(filterLower)
    );

    const filterForEgg = filterLower.split("-").join("").split(" ").join("");
    const idForEgg = props.id && props.id.split("-").join("").split(" ").join("");
    const easterEgg = (
        filterForEgg.includes("spiralcaselibrary") ||
        (idForEgg && idForEgg.includes("spiralcaselibrary"))
    );
    if( easterEgg ){
        history.push("/library/the-spiralcase-library");
    }
    
    return <div className=" Layout__Page  Decoration__Page">
        <div className="PagesLibrary__Panels">
            <div className="PagesLibrary__ListPanel">
                <div className="PagesLibrary__FilterLabel">Search:</div>
                <input className="PagesLibrary__Filter" placeholder="type the name of a card" type="text" value={filter} onChange={ e=>{setFilter(e.target.value)} }/>
                {
                    filteredCards.map(card=>
                        <Link
                            className={
                                "PagesLibrary__CardLink  "+
                                "PagesLibrary__CardLink--"+card.color
                            }
                            to={"/library/"+card.id}
                            onClick={()=>{
                                // have to set manually
                                setActiveCard(library.cards.find(_=>_.id===card.id));
                            }}
                        >
                        {card.title}
                        </Link>
                    )
                }
            </div>
            <div className="PagesLibrary__ImagePanel">
                {
                    activeCard && <img src={activeCard.href} />
                }
            </div>
        </div>
    </div>
}